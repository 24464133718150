import React, { useEffect } from "react";
import { useCookies } from "react-cookie";
import data from "./data.json";
import Descr from "./descr";

import bodyClasses from "../../../../utilities/sass/output/bodyStyle.module.css";

import Footer from "../../../footer/en/Footer";

import shuco from "../../../../utilities/media/img/partner/shuco.png";
import LogoBianco from "../../../../utilities/media/img/LOGO_BIANCO_Tavola_disegno_1.svg";
import MenuHome from "../../../menu/en/MenuHome";

function Body2() {
  useEffect(() => {
    document.title = "Di Monaco Serramenti - Home";
  });

  const [cookies, setCookies] = useCookies(["user"]);

  function handleCookies() {
    setCookies("user", "gowtham", {
      path: "/",
    });

    console.log(cookies)
  }

  return (
    <div id="cont_main" className={bodyClasses.cont__body}>
      <MenuHome section="home" />

      <div className={bodyClasses.cont__body_intro}>
        <img src={LogoBianco} alt="Logo Di Monaco" id="logo" />

        <img src={shuco} alt="Shuco" />
      </div>

      {data.map((data) => {
        return (
          <Descr
            id={data.id}
            key={data.id}
            title_1={data.title_1}
            title_2={data.title_2}
            description_1={data.description_1}
            description_2={data.description_2}
          />
        );
      })}

      <Footer />
    </div>
  );
}

export default Body2;
