import React from 'react'

import image1 from '../../../utilities/media/img/partner/pa1.png'
import image2 from '../../../utilities/media/img/partner/pa2.png'
import image3 from '../../../utilities/media/img/partner/pa3.png'
import image4 from '../../../utilities/media/img/partner/pa4.png'
import image5 from '../../../utilities/media/img/partner/pa5.png'
import shuco from '../../../utilities/media/img/partner/sch-01.svg'

import Menu from '../../menu/en/Menu'

import sectionStyle from '../../../utilities/sass/output/sectionStyle.module.css'

import Footer from '../../footer/en/Footer'


function Partner() {
    let partners = [
        {
            "image": shuco,
            "alt": "Shuco",
            "link": "https://www.schueco.com/web2/it"
        },
        {
            "image": image1,
            "alt": "Alucobond",
            "link": "https://alucobond.com/"
        },
        {
            "image": image2,
            "alt": "Saint-Gobain",
            "link": "https://www.saint-gobain.it/"
        },
        {
            "image": image3,
            "alt": "Griesser",
            "link": "https://www.griesser.it/it/home"
        },
        {
            "image": image4,
            "alt": "Metra",
            "link": "http://www.metra.eu/"
        },
        {
            "image": image5,
            "alt": "Ponzio",
            "link": "https://portal.ponzioaluminium.com/it/"
        }
    ]

    return (
        <div className={sectionStyle.cont__section}>
            <Menu section="inside" title="Our Partners" />


            <div className={sectionStyle.cont__section_wrapper}>
                <ul className={sectionStyle.cont__section_elements}>
                    {partners.map(el => <li id="partner" key={el.alt}><a href={el.link}><img alt={el.alt} src={el.image} /></a></li>)}
                </ul>

                <Footer />
            </div>
        </div>
    )
}

export default Partner
