import React, { useState } from "react";

// importing Browsing

import { Link } from "react-router-dom";

// importing Style

import menuClasses from "../../utilities/sass/output/menu.module.css";

// importing logo

import Logo from "../../utilities/media/img/LOGO_GRIGIO_Tavola_disegno_1.svg";
import Logo_1 from "../../utilities/media/img/logo_1.svg";

import Social_1 from "../../utilities/media/img/social_1.svg";
import Social_2 from "../../utilities/media/img/social_2.svg";
import Social_3 from "../../utilities/media/img/social_3.svg";

function Menu(props) {
  const [open, openMenu] = useState(false);

  const ShowMenu = () => {
    return (
      <div className={menuClasses.cont__menu} id="inside">
        <a href="https://www.dimonacoserramenti.it">
          <img src={Logo} alt="Logo Di Monaco Serramenti" />
        </a>

        <h3>{props.title}</h3>

        <h4>{props.subTitle}</h4>

        <nav>
          <ul className={menuClasses.cont__menu_nav}>
            <li>
              <Link to="/partner">I nostri Partner</Link>
            </li>
            <li>
              <Link to="/lavori">I nostri Lavori</Link>
            </li>
            <li>
              <Link to="/chiSiamo">Chi Siamo</Link>
            </li>
            <li>
              <Link to="/doveSiamo">Dove Siamo</Link>
            </li>
            <li>
              <Link to="/diventa-nostro-fornitore">Diventa nostro fornitore</Link>
            </li>
            <li>
              <Link to="/contatti">Il tuo Preventivo</Link>
            </li>
            <li id="contactButton">
              <Link to="/contatti">Ottieni più informazioni</Link>
            </li>
          </ul>
        </nav>
      </div>
    );
  };
  return (
    <>
      {ShowMenu()}

      {props.section === "inside" && (
        <div
          className={menuClasses.cont__menu_mobile_logo}
          id="logo_inside"
          style={{
            transform: open ? "translateY(-150%)" : "translateY(0)",
            transition: "transform .8s",
            opacity: props.map ? "0" : 1,
          }}
        >
<a href="https://www.dimonacoserramenti.it">
        <img src={Logo_1} alt="Logo Di Monaco Serramenti" />
        </a>
        </div>
      )}

      <div
        className={menuClasses.cont__menu_mobile}
        onClick={open ? () => openMenu(false) : () => openMenu(true)}
        id="menu_mobile_inside"
      >
        <span></span>
        <span></span>
        <span></span>
      </div>

      <div
        className={menuClasses.cont__menu_mobile_list}
        style={{ transform: open ? "translateY(0%)" : "translateY(-200%)" }}
      >
        <Link to="/">
          <img src={Logo} alt="Logo Di Monaco Serramenti" />
        </Link>

        <nav>
          <ul className={menuClasses.cont__menu_nav}>
            <li>
              {/* <Link
                                onClick={() => openMenu(false)} 
                                to="/prodotti">
                                I nostri Prodotti
                            </Link> */}
            </li>
            <li>
              <Link onClick={() => openMenu(false)} to="/partner">
                I nostri Partner
              </Link>
            </li>
            <li>
              <Link onClick={() => openMenu(false)} to="/lavori">
                I nostri Lavori
              </Link>
            </li>
            <li>
              <Link onClick={() => openMenu(false)} to="/chiSiamo">
                Chi Siamo
              </Link>
            </li>
            <li>
              <Link onClick={() => openMenu(false)} to="/doveSiamo">
                Dove Siamo
              </Link>
            </li>
<li>
              <Link onClick={() => openMenu(false)} to="/diventa-nostro-fornitore">
                Diventa nostro fornitore          
    </Link>
            </li>

            <li>
              <Link onClick={() => openMenu(false)} to="/contatti">
                Il tuo Preventivo
              </Link>
            </li>
            <li id="news">
              {/* <Link
                                onClick={() => openMenu(false)} 
                                to="/notizie">News
                            </Link> */}
            </li>
          </ul>

          <ul className={menuClasses.cont__menu_nav_backEnd}>
            <li>
              <Link
                onClick={() => {
                  alert("Area in Allestimento");
                  openMenu(false);
                }}
              >
                Privati
              </Link>
            </li>
            <li>
              <Link
                onClick={() => {
                  alert("Area in Allestimento");
                  openMenu(false);
                }}
                to="/doveSiamo"
              >
                Progettisti
              </Link>
            </li>
            <li>
              <Link
                onClick={() => {
                  alert("Area in Allestimento");
                  openMenu(false);
                }}
                to="/contatti"
              >
                Aziende
              </Link>
            </li>
          </ul>

          <ul className={menuClasses.cont__menu_nav_social}>
            <li>
              <a href="https://www.facebook.com/serramentiDiMonaco">
                <img src={Social_1} alt="Logo Social" />
              </a>
            </li>

            <li>
              <a href="https://instagram.com/dimonacoserramenti?igshid=NWRhNmQxMjQ=">
                <img src={Social_2} alt="Logo Social" />
              </a>
            </li>

            <li>
              <a href="tel:+390823878399">
                <img src={Social_3} alt="Logo Social" />
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </>
  );
}

export default Menu;
