import React, { useEffect } from "react";

import contactStyle from "../../../../utilities/sass/output/contactStyle.module.css";

import Menu from "../../../menu/en/Menu";

import Footer from "../../../footer/en/Footer";

function Contact() {
  useEffect(() => {
    document.title = "Di Monaco Serramenti - Contact us";
  });

  return (
    <div className={contactStyle.cont__contacts}>
      <Menu section="inside" title="Contact us" />

      <div className={contactStyle.cont__contacts_cont} id="sezioneContatti">
        <h2 className={contactStyle.cont__contacts_title}>
          Fill the form to reach us!
        </h2>

        <form className={contactStyle.cont__contacts_cont_form} action="https://www.dimonacoserramenti.it/formmail.php" method="POST">
          <input type="text" placeholder="First and Last name" name="nome" />
          <input type="text" placeholder="Phone" name="tel" />
          <input type="text" placeholder="Email" name="email" />
          <input type="text" placeholder="City" name="citta" />

          <textarea id="msg" placeholder="Message" rows="5" name="msg"></textarea>

          <input type="submit" />
        </form>

        <Footer />
      </div>
    </div>
  );
}

export default Contact;
