import React from 'react'

import sectionStyle from '../../../utilities/sass/output/sectionStyle.module.css'

// import Section 

import Section from '../section/Section'

// import Data

import productsArray from './products.json'

// import Menu 

import Menu from '../../menu/Menu'

function Product() {
    
    return (
        <div className={sectionStyle.cont__section}>
            <Menu 
                section="inside" 
                title="I Nostri Prodotti"
            />
            <Section elements={productsArray.products} documentTitle="Di Monaco Serramenti - I nostri Prodotti" />
        </div>
    )
}

export default Product
