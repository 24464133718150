import { createStore, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'

import rootReducer from './rootReducer'

// const getElements = (state = [], action) => {
//     switch(action.type){
//         case "ADD_NEWS":
//             return state.concat([action.text]);
//         default:
//             return state;
//     }   
// }

export const store = createStore(
    rootReducer,
    applyMiddleware(thunk)
);
