import React, { useEffect } from "react";

import contactStyle from "../../../utilities/sass/output/contactStyle.module.css";

import Menu from "../../menu/Menu";

import Footer from "../../footer/Footer";

function Contact() {
  useEffect(() => {
    document.title = "Di Monaco Serramenti - Contatti";
  });

  return (
    <div className={contactStyle.cont__contacts}>
      <Menu section="inside" title="Contattaci" />

      <div className={contactStyle.cont__contacts_cont} id="sezioneContatti">
        <h2 className={contactStyle.cont__contacts_title}>
          Compila il form per maggiori informazioni
        </h2>

        <form className={contactStyle.cont__contacts_cont_form} action="https://www.dimonacoserramenti.it/formmail.php" method="POST">
          <input type="text" placeholder="Nome - Cognome" name="nome" />
          <input type="text" placeholder="Telefono" name="tel" />
          <input type="text" placeholder="Email" name="email" />
          <input type="text" placeholder="Città" name="citta" />

          <textarea id="msg" placeholder="Messaggio" rows="5" name="msg"></textarea>

          <input type="submit" />
        </form>

        <Footer />
      </div>
    </div>
  );
}

export default Contact;
