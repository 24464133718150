import React from 'react'
import sectionStyle from '../../../utilities/sass/output/sectionStyle.module.css'

import newsArray from './newsPost.json'
import Menu from '../../menu/Menu'

// importing the Section template

import Section from '../section/Section'

function News() {

    // const parseJson = () => {

    // }

    // parseJson()
        return (
            <div className={sectionStyle.cont__section}>
                <Menu section="inside" title="News" subTitle="Le nostre novità" />
                <Section elements={newsArray.news} documentTitle = "Di Monaco Serramenti - Notizie" />
            </div>
        )
    }

export default News
