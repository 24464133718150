import React, { useState } from "react";

// importing Browsing

import { Link } from "react-router-dom";

// importing Style

import menuClasses from "../../../utilities/sass/output/menu.module.css";

// importing logo

import Logo from "../../../utilities/media/img/LOGO_GRIGIO_Tavola_disegno_1.svg";
import Logo_1 from "../../../utilities/media/img/logo_1.svg";

import Social_1 from "../../../utilities/media/img/social_1.svg";
import Social_2 from "../../../utilities/media/img/social_2.svg";
import Social_3 from "../../../utilities/media/img/social_3.svg";

function Menu(props) {
  const [open, openMenu] = useState(false);

  const ShowMenu = () => {
    return (
      <div className={menuClasses.cont__menu} id="inside">
        <a href="https://www.dimonacoserramenti.it/#/eng/">
          <img src={Logo} alt="Logo Di Monaco Serramenti" />
        </a>

        <h3>{props.title}</h3>

        <h4>{props.subTitle}</h4>

        <nav>
          <ul className={menuClasses.cont__menu_nav}>
            <li>
              <Link to="/partners">Our Partners</Link>
            </li>
            <li>
              <Link to="/works">Our Works</Link>
            </li>
            <li>
              <Link to="/about-us">About Us</Link>
            </li>
            <li>
              <Link to="/where">Where to find us</Link>
            </li>
            <li>
              <Link to="/contact">Ask for a preventive</Link>
            </li>
            <li>
              <Link to="/become-a-supplier">Become a supplier</Link>
            </li>
            <li id="contactButton">
              <Link to="/contact">Get more informations</Link>
            </li>
          </ul>
        </nav>
      </div>
    );
  };
  return (
    <>
      {ShowMenu()}

      {props.section === "inside" && (
        <div
          className={menuClasses.cont__menu_mobile_logo}
          id="logo_inside"
          style={{
            transform: open ? "translateY(-150%)" : "translateY(0)",
            transition: "transform .8s",
            opacity: props.map ? "0" : 1,
          }}
        >
          <a href="https://www.dimonacoserramenti.it">
            <img src={Logo_1} alt="Logo Di Monaco Serramenti" />
          </a>
        </div>
      )}

      <div
        className={menuClasses.cont__menu_mobile}
        onClick={open ? () => openMenu(false) : () => openMenu(true)}
        id="menu_mobile_inside"
      >
        <span></span>
        <span></span>
        <span></span>
      </div>

      <div
        className={menuClasses.cont__menu_mobile_list}
        style={{ transform: open ? "translateY(0%)" : "translateY(-200%)" }}
      >
        <Link to="/">
          <img src={Logo} alt="Logo Di Monaco Serramenti" />
        </Link>

        <nav>
          <ul className={menuClasses.cont__menu_nav}>
            <li>
              {/* <Link
                                onClick={() => openMenu(false)} 
                                to="/prodotti">
                                I nostri Prodotti
                            </Link> */}
            </li>
            <li>
              <Link onClick={() => openMenu(false)} to="/partners">
                Our Partners
              </Link>
            </li>
            <li>
              <Link onClick={() => openMenu(false)} to="/works">
                Our Works
              </Link>
            </li>
            <li>
              <Link onClick={() => openMenu(false)} to="/about-us">
                About Us
              </Link>
            </li>
            <li>
              <Link onClick={() => openMenu(false)} to="/where">
                Where to find us
              </Link>
            </li>
            <li>
              <Link
                onClick={() => openMenu(false)}
                to="/become-a-supplier"
              >
                Become a Supplier
              </Link>
            </li>

            <li>
              <Link onClick={() => openMenu(false)} to="/contact">
                Ask for a preventive
              </Link>
            </li>
            <li id="news">
              {/* <Link
                                onClick={() => openMenu(false)} 
                                to="/notizie">News
                            </Link> */}
            </li>
          </ul>

          <ul className={menuClasses.cont__menu_nav_social}>
            <li>
              <a href="https://www.facebook.com/serramentiDiMonaco">
                <img src={Social_1} alt="Logo Social" />
              </a>
            </li>

            <li>
              <a href="https://instagram.com/dimonacoserramenti?igshid=NWRhNmQxMjQ=">
                <img src={Social_2} alt="Logo Social" />
              </a>
            </li>

            <li>
              <a href="tel:+390823878399">
                <img src={Social_3} alt="Logo Social" />
              </a>
            </li>
          </ul>

          <ul className={menuClasses.cont__menu_nav_lang}>
            <li>
              <Link to="/">ITA</Link>
            </li>{" "}
            |
            <li>
              <Link to="/eng/">ENG</Link>
            </li>
          </ul>
        </nav>
      </div>
    </>
  );
}

export default Menu;
