import React from "react";

import { Link } from "react-router-dom";

import footerStyle from "../../utilities/sass/output/footerStyle.module.css";

import Logo from "../../utilities/media/img/LOGO_BIANCO_Tavola_disegno_1.svg";

import Aicon from "../../utilities/media/img/logoaicon.png";

function Footer() {
  return (
    <div className={footerStyle.cont__footer}>
      <div className={footerStyle.cont__footer_inside}>
        <Link to="/">
          <img src={Logo} alt="Logo Pagano Serramenti" />
        </Link>

        <nav>
          <ul>
            <h2>About</h2>

            <li>
              <Link to="/lavori">I nostri Lavori</Link>
            </li>

            <li>
              <Link to="/partner">I nostri Partner</Link>
            </li>

            <li>
              <Link to="/chiSiamo">Chi Siamo</Link>
            </li>

            <li>
              <a href="https://www.iubenda.com/privacy-policy/53365305" class="iubenda-white no-brand iubenda-noiframe iubenda-embed iubenda-noiframe " title="Privacy Policy ">Privacy Policy</a>
            </li>

            <li>
            <a href="https://www.iubenda.com/privacy-policy/53365305/cookie-policy" class="iubenda-white no-brand iubenda-noiframe iubenda-embed iubenda-noiframe " title="Cookie Policy ">Cookie Policy</a>
            </li>
            
            <li>
              <a href="https://www.iubenda.com/termini-e-condizioni/53365305" class="iubenda-white no-brand iubenda-noiframe iubenda-embed iubenda-noiframe " title="Termini e Condizioni ">Termini e Condizioni</a>
            </li>

          </ul>

          <ul>
            <h2>Contatti</h2>

            <li id="preventivo">
              <Link to="/contatti">Chiedi un Preventivo</Link>
            </li>

            <li>
              Sede:{" "}
              <a href="https://goo.gl/maps/axbMfc3f1NUNPEFo6">
                Via S.S. Sannitica Zona Pip - 81040 - Loc. Truli - Castel di
                Sasso (Caserta)
              </a>
            </li>

            <li>
              Tel: <a href="tel:+390823878399">0823 878399</a>
            </li>

            <li>
              Email:{" "}
              <a href="mailto: dimonacoserramenti@libero.it">
                dimonacoserramenti@libero.it
              </a>
            </li>
          </ul>
        </nav>
      </div>

      <div className={footerStyle.cont__footer_under}>
        <p>Powered by</p>
        <img src={Aicon} alt="Aicon" />
      </div>
    </div>
  );
}

export default Footer;
