import React, { useEffect } from "react";

import Menu from "../../menu/Menu";

import Footer from "../../footer/Footer";

import "../../../../node_modules/react-slideshow-image/dist/styles.css";

import aboutStyle from "../../../utilities/sass/output/aboutStyle.module.css";

import firstImage from "../../../utilities/media/img/chiSiamo/foto01.jpg";
import secondImage from "../../../utilities/media/img/chiSiamo/foto05.jpg";
import thirdImage from "../../../utilities/media/img/chiSiamo/foto06.jpg";

function About() {
  useEffect(() => {
    document.title = "Di Monaco Serramenti - Chi Siamo";
  });

  return (
    <div className={aboutStyle.cont__about}>
      <Menu section="inside" title="Chi Siamo" />

      <div className={aboutStyle.cont__about_inside}>
        <h3>Una storia di Oltre 50 anni</h3>
        <h4>Un'organizzazione che mira alla qualità</h4>

        <p>
          Negli anni sessanta, Agostino di Monaco, fondò la Di Monaco Serramenti
          grazie al suo impegno costante e alla sua maestria nel produrre
          serramenti in alluminio. <br /> <br />
          Nel 2007, con il trasferimento nella nuova sede da San Prisco a Castel
          di Sasso, l’azienda si trasforma in società di capitali con il nome Di
          Monaco Serramenti Srl. Grazie ad un costante sviluppo, oggi
          rappresenta un’importante realtà nel settore dei serramenti in
          alluminio, alluminio-legno e facciate continue. <br /> <br />
          L’azienda oggi collabora con grandi aziende produttrici di sistemi e
          prodotti quali Schuco per i profili in alluminio e sistemi
          d’avanguardia. L’ organizzazione e la produzione degli infissi si
          sviluppa su un’area di circa 10.000mq, di cui 1.300 occupati dal
          capannone e 150 di sala esposizione.
        </p>

        <div className={aboutStyle.cont__about_inside_slide}>
          <img src={firstImage} alt="Di Monaco Serramenti" />
          <img src={thirdImage} alt="Di Monaco Serramenti" />
          <img src={secondImage} alt="Di Monaco Serramenti" />
        </div>

        <p>
          Tutto il lavoro svolto, è conforme alle norme internazionali della
          qualità ISO 9001, al sistema di gestione Ambientale ISO 14001 ed è
          certificato dall’attestazione SOA di qualificazione all’esecuzione di
          lavori pubblici. Ogni fase produttiva viene costantemente monitorata
          al fine di garantire un prodotto di elevata qualità. Apposite
          procedure opportunamente implementate, permettono la realizzazione e
          l’installazione finale di ogni tipo di serramento alla perfezione.
        </p>

        <Footer />
      </div>
    </div>
  );
}

export default About;
