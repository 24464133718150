import React from 'react'
import bodyStyle from '../../../utilities/sass/output/bodyStyle.module.css'
import {Link} from 'react-router-dom'


function descr(props) {
    return (
        <div className={bodyStyle.cont__body_info}>
            <div className={bodyStyle.cont__body_info_descr}>
                <h2>{props.title_1}</h2>

                <p>{props.description_1}</p>

                <h2>{props.title_2}</h2>

                <p>{props.description_2}</p>

                <Link to="/contact">
                    Ottieni più informazioni
                </Link>
            </div>

            <div className={bodyStyle.cont__body_info_descr_image} id={props.id}></div>
        </div>
    )
}

export default descr
