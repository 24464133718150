import React, { useEffect } from "react";

import Menu from "../../../menu/en/Menu";

import Footer from "../../../footer/en/Footer";

import "../../../../../node_modules/react-slideshow-image/dist/styles.css";

import aboutStyle from "../../../../utilities/sass/output/aboutStyle.module.css";

import firstImage from "../../../../utilities/media/img/chiSiamo/foto01.jpg";
import secondImage from "../../../../utilities/media/img/chiSiamo/foto05.jpg";
import thirdImage from "../../../../utilities/media/img/chiSiamo/foto06.jpg";

function About2() {
  useEffect(() => {
    document.title = "Di Monaco Serramenti - About Us";
  });

  return (
    <div className={aboutStyle.cont__about}>
      <Menu section="inside" title="About US" />

      <div className={aboutStyle.cont__about_inside}>
        <h3>A Story of more than 50 years old</h3>
        <h4>An organization which aims at quality</h4>

        <p>
          In the sixties Agostino di Monaco founded Di Monaco Serramenti thanks
          to his constant commitment and his mastery in producing aluminum
          windows. <br /> <br />
          In 2007 with the transfer to the new headquarters from San Prisco to
          Castel di Sasso, the company becomes a joint-stock company with the
          name Di Monaco Serramenti S.r.l. Thanks to constant development, today
          represents an important reality in the window and door sector
          aluminium, aluminium-wood and curtain walls. <br /> <br />
          The company today collaborates with large companies producing
          electronic systems products such as Schuco for aluminum profiles and
          systems avant-garde. The organization and production of the displays
          yes it spreads over an area of approximately 10,000 mq2, of which
          1,300 occupied by warehouse and 150 showrooms.
        </p>

        <div className={aboutStyle.cont__about_inside_slide}>
          <img src={firstImage} alt="Di Monaco Serramenti" />
          <img src={thirdImage} alt="Di Monaco Serramenti" />
          <img src={secondImage} alt="Di Monaco Serramenti" />
        </div>

        <p>
          All work performed complies with international standards ISO
          9001 quality, ISO 14001 environmental management system and is
          certified by the SOA certification of qualification for the execution
          of public Works. Each production phase is constantly monitored in
          order to guarantee a high quality product. Appropriate appropriately
          implemented procedures allow the realization and the final
          installation of each type of window and door in a workmanlike manner.
        </p>

        <Footer />
      </div>
    </div>
  );
}

export default About2;
