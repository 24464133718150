import React, {useEffect} from 'react'
import sectionStyle from '../../../../utilities/sass/output/sectionStyle.module.css'

import Menu from '../../../menu/en/Menu'

function Where() {
    useEffect(() => {
        document.title = 'Di Monaco Serramenti - Where to find us'
    })

    return (
        <div className={sectionStyle.cont__section}>
            <Menu section="inside" title="Come to us" map/>

            <iframe className={sectionStyle.cont__section_map} src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3004.3064400892576!2d14.28644841574511!3d41.14966417928672!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x133af804ccda213b%3A0xe67dcc9e1dad6cf8!2sDi%20Monaco%20Serramenti%20S.R.L.!5e0!3m2!1sit!2sit!4v1613050210791!5m2!1sit!2sit" title="map" frameBorder="0" style={{"border": "0"}} allowFullScreen="" ariaHidden="false" tabIndex="0"></iframe>
        </div>
    )
}

export default Where
