import React, { useState } from "react";
import { Link } from "react-router-dom";

// importing Style

import menuClasses from "../../utilities/sass/output/menu.module.css";

// importing logo

import Logo from "../../utilities/media/img/LOGO_GRIGIO_Tavola_disegno_1.svg";

import Social_1 from "../../utilities/media/img/social_1.svg";
import Social_2 from "../../utilities/media/img/social_2.svg";
import Social_3 from "../../utilities/media/img/social_3.svg";

function MenuHome(props) {
  const [open, openMenu] = useState(false);

  setTimeout(() => {
    const main = document.querySelector("#cont_main");

    const scrollEvent = () => {
      if (main.scrollTop >= 1) {
        document.getElementById("menu__home").style.transform =
          "translateX(101%)";
        document.getElementById("menu__home").style.transition =
          "transform .7s";
      } else {
        document.getElementById("menu__home").style.transform = "translateX(0)";
        document.getElementById("menu__home").style.transition =
          "transform .7s";
      }
    };

    if (props.section === "home") {
      main.addEventListener("scroll", scrollEvent);
    }
  }, 1000);

  return (
    <>
      <div id="menu__home" className={menuClasses.cont__menu}>
        <Link to="/">
          <img src={Logo} alt="Logo Di Monaco Serramenti" />
        </Link>

        <nav>
          <ul className={menuClasses.cont__menu_nav}>
            {/* <li>
                                    <Link to="/prodotti">I nostri Prodotti</Link>
                                </li> */}
            <li>
              <Link to="/partner">I nostri Partner</Link>
            </li>
            <li>
              <Link to="/lavori">I nostri Lavori</Link>
            </li>
            <li>
              <Link to="/chiSiamo">Chi Siamo</Link>
            </li>
            <li>
              <Link to="/doveSiamo">Dove Siamo</Link>
            </li>
            <li>
              <Link to="/contatti">Il tuo Preventivo</Link>
            </li>
            <li>
              <Link to="/diventa-nostro-fornitore">
                Diventa nostro Fornitore
              </Link>
            </li>
            {/* <li id="news">
                                    <Link to="/notizie">News</Link>
                                </li> */}
          </ul>
        </nav>

        <ul className={menuClasses.cont__menu_nav_social}>
          <li>
            <a href="https://www.facebook.com/serramentiDiMonaco">
              <img src={Social_1} alt="Logo Social" />
            </a>
          </li>

          <li>
            <a href="https://instagram.com/dimonacoserramenti?igshid=NWRhNmQxMjQ=">
              <img src={Social_2} alt="Logo Social" />
            </a>
          </li>

          <li>
            <a href="tel:+390823878399">
              <img src={Social_3} alt="Logo Social" />
            </a>
          </li>
        </ul>

        <ul className={menuClasses.cont__menu_lang}>
          <li>
            <Link to="/">ITA</Link>
          </li>
          |
          <li>
            <Link to="/eng/">Eng</Link>{" "}
          </li>
        </ul>
      </div>

      <div
        className={menuClasses.cont__menu_mobile}
        onClick={open ? () => openMenu(false) : () => openMenu(true)}
        id="menu_mobile_inside"
      >
        <span></span>
        <span></span>
        <span></span>
      </div>

      <div
        className={menuClasses.cont__menu_mobile_list}
        style={{ transform: open ? "translateY(0%)" : "translateY(-101%)" }}
      >
        <Link to="/">
          <img src={Logo} alt="Logo Di Monaco Serramenti" />
        </Link>

        <nav>
          <ul className={menuClasses.cont__menu_nav}>
            <li>
              {/* <Link
                        onClick={() => openMenu(false)} 
                        to="/prodotti">
                        I nostri Prodotti
                    </Link> */}
            </li>
            <li>
              <Link onClick={() => openMenu(false)} to="/partner">
                I nostri Partner
              </Link>
            </li>
            <li>
              <Link onClick={() => openMenu(false)} to="/lavori">
                I nostri Lavori
              </Link>
            </li>
            <li>
              <Link onClick={() => openMenu(false)} to="/chiSiamo">
                Chi Siamo
              </Link>
            </li>
            <li>
              <Link onClick={() => openMenu(false)} to="/doveSiamo">
                Dove Siamo
              </Link>
            </li>
            <li>
              <Link
                onClick={() => openMenu(false)}
                to="/diventa-nostro-fornitore"
              >
                Diventa nostro Fornitore
              </Link>
            </li>
            <li>
              <Link onClick={() => openMenu(false)} to="/contatti">
                Il tuo Preventivo
              </Link>
            </li>
            <li id="news">
              {/* <Link
                        onClick={() => openMenu(false)} 
                        to="/notizie">News
                    </Link> */}
            </li>
          </ul>

          <ul className={menuClasses.cont__menu_lang} style={{margin: "auto"}}>
            <li>
              <Link to="/">ITA</Link>
            </li>
            |
            <li>
              <Link to="/eng/">Eng</Link>{" "}
            </li>
          </ul>

          <ul className={menuClasses.cont__menu_nav_social}>
            <li>
              <a href="https://www.facebook.com/serramentiDiMonaco">
                <img src={Social_1} alt="Logo Social" />
              </a>
            </li>

            <li>
              <a href="https://instagram.com/dimonacoserramenti?igshid=NWRhNmQxMjQ=">
                <img src={Social_2} alt="Logo Social" />
              </a>
            </li>

            <li>
              <a href="tel:+390823878399">
                <img src={Social_3} alt="Logo Social" />
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </>
  );
}

export default MenuHome;
