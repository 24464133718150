import React, { Suspense } from "react";
import sectionStyle from "../../../../utilities/sass/output/sectionStyle.module.css";

import Menu from "../../../menu/en/Menu";

import Footer from "../../../footer/en/Footer";

function Work() {
  const WorkImages = React.lazy(() => import("./WorkImages"));

  return (
    <div className={sectionStyle.cont__section}>
      <Menu section="inside" title="Our Works" />

      <div className={sectionStyle.cont__section_wrapper}>
        <ul className={sectionStyle.cont__section_elements}>
          <Suspense fallback={<li>...loading</li>}>
            <WorkImages
              renderClass={sectionStyle.cont__section_elements_renderedImages}
            />
          </Suspense>
        </ul>

        <Footer />
      </div>
    </div>
  );
}

export default Work;
