import React, { useEffect, Suspense } from "react";
import sectionStyle from "../../../utilities/sass/output/sectionStyle.module.css";

function Section(props) {
  useEffect(() => {
    document.title = props.documentTitle;
  });

  return (
    <>
      <ul id="list" className={sectionStyle.cont__section_elements}>
        <Suspense fallback={<h1>Caricament...</h1>}>
          {props?.elements.map((el) => {
            let image = new Image();
            image.src = el.image;
            console.log(image);

            return (
              <li key={el.id} id="image_news">
                <a href={el?.link}>
                  <h4>{el.title}</h4>

                  <p>{el.text}</p>
                </a>
              </li>
            );
          })}
        </Suspense>
      </ul>
    </>
  );
}

export default Section;
