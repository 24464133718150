import React from "react";

// importing Router

import { HashRouter as Router, Switch, Route } from "react-router-dom";

// import Main page

import MainPage from "./components/main/body/Body";

// import About Page

import About from "./components/main/about/About";

// import News Section

import News from "./components/main/news/News";

// import Contatt section

import Contacts from "./components/main/contacts/Contact";

// import Products section

import Products from "./components/main/products/Product";

// import Work section

import Works from "./components/main/works/Work";

// import Maps

import Where from "./components/main/where/Where";

// import Partner

import Partner from "./components/partner/Partner";

// import Fornitore

import Fornitore from './components/main/contacts/Fornitore'

// import Cookie

import { CookiesProvider } from "react-cookie";
import PrivacyPolicy from "./privacyPolicy";
import Body from "./components/main/body/en/Body";
import About2 from "./components/main/about/en/About";
import Work from "./components/main/works/en/Work";
import Partners from "./components/partner/en/Partner";
import Where2 from "./components/main/where/en/Where"
import Contact2 from "./components/main/contacts/en/Contact";
import Supplier from "./components/main/contacts/en/Fornitore"

function App() {
  return (
    <CookiesProvider>
      <Router>
        <div className="App">
          <Switch>
            <Route path="/eng/">
              <Body />
            </Route>

            <Route path="/where">
              <Where2 />
            </Route>

            <Route path="/contact">
              <Contact2 />
            </Route>

            <Route path="/become-a-supplier">
              <Supplier />
            </Route>

            <Route path="/about-us">
              <About2 />
            </Route>

            <Route path="/works">
              <Work />
            </Route>

            <Route path="/partners">
              <Partners />
            </Route>

            <Route path="/privacyPolicy">
              <PrivacyPolicy />
            </Route>

            <Route path="/contatti">
              <Contacts />
            </Route>

            <Route path="/notizie">
              <News />
            </Route>

            <Route path="/doveSiamo">
              <Where />
            </Route>

            <Route path="/partner">
              <Partner />
            </Route>

            <Route path="/lavori">
              <Works />
            </Route>

            <Route path="/diventa-nostro-fornitore">
              <Fornitore />
            </Route>

            <Route path="/prodotti">
              <Products />
            </Route>

            <Route path={process.env.PUBLIC_URL + "/chiSiamo"}>
              <About />
            </Route>

            <Route path="/">
              <MainPage />
            </Route>
          </Switch>
        </div>
      </Router>
    </CookiesProvider>
  );
}

export default App;
